// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  width: 100%;
  background: var(--dark);
}

nav > div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 5px 80px;
  box-sizing: border-box;
}
.space-around {
  justify-content: space-around;
}
nav > div strong {
  font-size: 1.7em;
  color: white;
  cursor: pointer;
}

nav > div .images-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

nav > div .images-container img {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

nav .link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 5px 10px;
  transition: all 0.3s ease;
}

nav .link img {
  width: 28px;
  rotate: 90deg;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Navbar/Navbar.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,qBAAqB;EACrB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,0CAA0C;EAC1C,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":["@import url(\"/src/App.css\");\n\nnav {\n  width: 100%;\n  background: var(--dark);\n}\n\nnav > div {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: 100%;\n  padding: 5px 80px;\n  box-sizing: border-box;\n}\n.space-around {\n  justify-content: space-around;\n}\nnav > div strong {\n  font-size: 1.7em;\n  color: white;\n  cursor: pointer;\n}\n\nnav > div .images-container {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\nnav > div .images-container img {\n  width: 45px;\n  height: 45px;\n  cursor: pointer;\n}\n\nnav .link {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  text-decoration: none;\n  color: white;\n  font-size: 20px;\n  border-radius: 5px;\n  border: 1px solid rgba(255, 255, 255, 0.3);\n  padding: 5px 10px;\n  transition: all 0.3s ease;\n}\n\nnav .link img {\n  width: 28px;\n  rotate: 90deg;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

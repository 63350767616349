// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#work-exp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

#work-exp h2 {
  font-size: 3em;
  color: var(--green);
  margin-bottom: 20px;
}

#work-exp .work-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

@media (max-width: 700px) {
  #work-exp h2 {
    font-size: 2.2em;
  }

  #work-exp .work-items-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    box-sizing: border-box;
    padding: 0px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkExperience/WorkExperience.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,sBAAsB;IACtB,iBAAiB;EACnB;AACF","sourcesContent":["@import url(\"/src/App.css\");\n#work-exp {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 20px;\n}\n\n#work-exp h2 {\n  font-size: 3em;\n  color: var(--green);\n  margin-bottom: 20px;\n}\n\n#work-exp .work-items-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 20px;\n}\n\n@media (max-width: 700px) {\n  #work-exp h2 {\n    font-size: 2.2em;\n  }\n\n  #work-exp .work-items-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    gap: 20px;\n    box-sizing: border-box;\n    padding: 0px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#personal-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

#personal-projects h2 {
  font-size: 3em;
  color: var(--green);
  margin-bottom: 20px;
}

#personal-projects section {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-template-rows: repeat(2, 300px);
  gap: 50px 50px;
  padding: 15px;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  #personal-projects {
    gap: 20px;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  #personal-projects h2 {
    font-size: 2.2em;
  }
  #personal-projects section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PersonalProjects/PersonalProjects.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,oDAAoD;EACpD,oCAAoC;EACpC,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,SAAS;IACT,iBAAiB;IACjB,sBAAsB;EACxB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,SAAS;EACX;AACF","sourcesContent":["@import url(\"/src/App.css\");\n#personal-projects {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 20px;\n}\n\n#personal-projects h2 {\n  font-size: 3em;\n  color: var(--green);\n  margin-bottom: 20px;\n}\n\n#personal-projects section {\n  display: grid;\n  width: 100%;\n  grid-template-columns: repeat(2, minmax(300px, 1fr));\n  grid-template-rows: repeat(2, 300px);\n  gap: 50px 50px;\n  padding: 15px;\n  box-sizing: border-box;\n}\n\n@media (max-width: 700px) {\n  #personal-projects {\n    gap: 20px;\n    padding: 0px 10px;\n    box-sizing: border-box;\n  }\n  #personal-projects h2 {\n    font-size: 2.2em;\n  }\n  #personal-projects section {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    gap: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bento-grid .left-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex: 1 1;
  height: 100%;
}

.bento-grid .left-grid p {
  text-align: center;
}

.project-main .left-grid h3 {
  width: 100%;
  text-align: center;
  font-size: 3.2em;
  letter-spacing: 2px;
  background: linear-gradient(180deg, var(--green) 36.98%, var(--light-green) 93.79%);
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.project-main .left-grid a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: var(--very-dark-green);
  font-weight: 700;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--green);
  border-radius: 6px;
  font-size: 1.2em;
  transition: all 0.3s ease;
}

.project-main .left-grid a:hover {
  background-color: var(--light-green);
}

.project-main .left-grid a img {
  width: 32px;
  height: 32px;
}

@media (max-width: 700px) {
  .bento-grid .left-grid {
    gap: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectsPage/LeftGrid/LeftGrid.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,SAAO;EACP,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mFAAmF;EACnF,4BAA4B;EAC5B,6BAA6B;EAC7B,oCAAoC;EACpC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,qBAAqB;EACrB,6BAA6B;EAC7B,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":["@import url('/src/App.css');\n\n.bento-grid .left-grid {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 20px;\n  flex: 1;\n  height: 100%;\n}\n\n.bento-grid .left-grid p {\n  text-align: center;\n}\n\n.project-main .left-grid h3 {\n  width: 100%;\n  text-align: center;\n  font-size: 3.2em;\n  letter-spacing: 2px;\n  background: linear-gradient(180deg, var(--green) 36.98%, var(--light-green) 93.79%);\n  background-clip: content-box;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  margin: 0;\n}\n\n.project-main .left-grid a {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  text-decoration: none;\n  color: var(--very-dark-green);\n  font-weight: 700;\n  padding: 10px;\n  width: 100%;\n  box-sizing: border-box;\n  background-color: var(--green);\n  border-radius: 6px;\n  font-size: 1.2em;\n  transition: all 0.3s ease;\n}\n\n.project-main .left-grid a:hover {\n  background-color: var(--light-green);\n}\n\n.project-main .left-grid a img {\n  width: 32px;\n  height: 32px;\n}\n\n@media (max-width: 700px) {\n  .bento-grid .left-grid {\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.work-item > div img {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
}

.rotate {
  rotate: -90deg;
}

.work-item > div strong {
  font-size: 1.5em;
  color: var(--green);
  margin: 0;
  cursor: pointer;
}

.work-item > div span {
  font-size: 1em;
  font-weight: 400;
  color: var(--white-green);
  margin-left: 10px;
  font-style: italic;
}

@media (max-width: 700px) {
  .work-item > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    width: 100%;
  }

  .work-item .company {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .work-item > div strong {
    font-size: 1.3em;
    width: 100%;
    text-align: left;
  }

  .work-item > div strong::nth-child(2) {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkExperience/WorkItemHeader.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,WAAW;EACb;EACA;IACE,gBAAgB;IAChB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@import url('/src/App.css');\n\n.work-item > div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 15px;\n  cursor: pointer;\n}\n.work-item > div img {\n  width: 30px;\n  height: 30px;\n  transition: all 0.3s ease;\n}\n\n.rotate {\n  rotate: -90deg;\n}\n\n.work-item > div strong {\n  font-size: 1.5em;\n  color: var(--green);\n  margin: 0;\n  cursor: pointer;\n}\n\n.work-item > div span {\n  font-size: 1em;\n  font-weight: 400;\n  color: var(--white-green);\n  margin-left: 10px;\n  font-style: italic;\n}\n\n@media (max-width: 700px) {\n  .work-item > div {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 15px;\n    cursor: pointer;\n    width: 100%;\n  }\n\n  .work-item .company {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    width: 100%;\n  }\n  .work-item > div strong {\n    font-size: 1.3em;\n    width: 100%;\n    text-align: left;\n  }\n\n  .work-item > div strong::nth-child(2) {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
  transition: box-shadow 0.5s linear;
}

.project-container:hover {
  box-shadow: 0 0 10px 3px var(--light-green);
}

.project-container h3 {
  font-size: 1.8em;
  color: var(--light-green);
  letter-spacing: 2px;
  margin-bottom: 10px;

}

 a {
  text-decoration: none;
}

.project-container p {
  color: white;
  text-align: left;
}

.project-container .stack-tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  margin-top: 10px;
}

.tag {
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 5px;
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/PersonalProjects/PersonalProjectItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;;AAErB;;CAEC;EACC,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,QAAQ;EACR,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,oCAAoC;EACpC,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".project-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  width: 100%;\n  height: 100%;\n  gap: 15px;\n  background: rgba(255, 255, 255, 0.1);\n  padding: 15px;\n  box-sizing: border-box;\n  border-radius: 12px;\n  cursor: pointer;\n  transition: box-shadow 0.5s linear;\n}\n\n.project-container:hover {\n  box-shadow: 0 0 10px 3px var(--light-green);\n}\n\n.project-container h3 {\n  font-size: 1.8em;\n  color: var(--light-green);\n  letter-spacing: 2px;\n  margin-bottom: 10px;\n\n}\n\n a {\n  text-decoration: none;\n}\n\n.project-container p {\n  color: white;\n  text-align: left;\n}\n\n.project-container .stack-tags {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 5px;\n  margin-top: 10px;\n}\n\n.tag {\n  padding: 5px 10px;\n  border-radius: 5px;\n  background: rgba(255, 255, 255, 0.1);\n  color: white;\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-right: 5px;\n  margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

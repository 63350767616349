// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../App.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bento-grid .right-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}


.project-main .right-grid p {
  text-align: left;
  color: rgb(197, 197, 197);
  font-size: 1.1em;
}

.project-main .left-grid p {
  margin-top: 5px;
  text-align: center;
  color: rgb(197, 197, 197);
  font-size: 1.1em;
}

@media (max-width: 700px) {
  .bento-grid .right-grid {
    gap: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectsPage/RightGrid/RightGrid.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;;AAGA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":["@import url('/src/App.css');\n\n.bento-grid .right-grid {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 20px;\n}\n\n\n.project-main .right-grid p {\n  text-align: left;\n  color: rgb(197, 197, 197);\n  font-size: 1.1em;\n}\n\n.project-main .left-grid p {\n  margin-top: 5px;\n  text-align: center;\n  color: rgb(197, 197, 197);\n  font-size: 1.1em;\n}\n\n@media (max-width: 700px) {\n  .bento-grid .right-grid {\n    gap: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

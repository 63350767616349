// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-stack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.tech-stack .tech-icon {
  width: 75px;
  height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/components/WorkExperience/TechStack.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".tech-stack {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  gap: 25px;\n  flex-wrap: wrap;\n  margin-top: 1rem;\n}\n\n.tech-stack .tech-icon {\n  width: 75px;\n  height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background: var(--dark);
}

:root {
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--light-green) var(--darkest-green);
  --dark: rgb(16, 17, 18);
  --darkest-green: #021912;
  --very-dark-green: #06281d;
  --dark-green: #0d4732;
  --mid-dark-green: #0f563c;
  --mid-green: #148759;
  --green: #22a770;
  --light-green: #7bdaac;
  --white-green: #eefbf4; /* #rgba(238, 251, 244, 1) */
  --font-family: "Roboto", sans-serif;
}

/* For Webkit browsers (Chrome, Safari) */
:root ::-webkit-scrollbar {
  width: 8px;
  background-color: var(--darkest-green);
}

:root ::-webkit-scrollbar-thumb {
  background-color: var(--darkest-green);
}

:root::-webkit-scrollbar-track {
  background-color: var(--darkest-green);;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,wDAAwD;EACxD,uBAAuB;EACvB,wBAAwB;EACxB,0BAA0B;EAC1B,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB;EACpB,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB,EAAE,4BAA4B;EACpD,mCAAmC;AACrC;;AAEA,yCAAyC;AACzC;EACE,UAAU;EACV,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap\");\n.App {\n  min-height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  text-align: center;\n  font-family: \"Roboto\", sans-serif;\n  background: var(--dark);\n}\n\n:root {\n  overflow: auto;\n  overflow-x: hidden;\n  scrollbar-width: thin;\n  scrollbar-color: var(--light-green) var(--darkest-green);\n  --dark: rgb(16, 17, 18);\n  --darkest-green: #021912;\n  --very-dark-green: #06281d;\n  --dark-green: #0d4732;\n  --mid-dark-green: #0f563c;\n  --mid-green: #148759;\n  --green: #22a770;\n  --light-green: #7bdaac;\n  --white-green: #eefbf4; /* #rgba(238, 251, 244, 1) */\n  --font-family: \"Roboto\", sans-serif;\n}\n\n/* For Webkit browsers (Chrome, Safari) */\n:root ::-webkit-scrollbar {\n  width: 8px;\n  background-color: var(--darkest-green);\n}\n\n:root ::-webkit-scrollbar-thumb {\n  background-color: var(--darkest-green);\n}\n\n:root::-webkit-scrollbar-track {\n  background-color: var(--darkest-green);;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
